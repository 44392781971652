var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("h4", [
        _c("i", { staticClass: "nav-icon icon-layers" }),
        _vm._v(" Authority Levels")
      ]),
      _c("hr"),
      _c("h5", [
        _vm._v("Select Project "),
        _c("i", {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: { hover: true }
            }
          ],
          staticClass: "ml-2 fa fa fa-question-circle",
          attrs: {
            title: "Project Manager is project based. Manager is global."
          }
        })
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.projectid,
              expression: "projectid"
            }
          ],
          staticClass: "select-account form-control w-50 mb-4",
          staticStyle: { height: "42px" },
          attrs: { name: "editprojectid" },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.projectid = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                return _vm.loadData()
              }
            ]
          }
        },
        [
          _c("option", { attrs: { value: "0", disabled: "", selected: "" } }, [
            _vm._v("Select Project")
          ]),
          _vm._l(_vm.projectData, function(option) {
            return _c(
              "option",
              { attrs: { for: "" }, domProps: { value: option.projectid } },
              [_vm._v(_vm._s(option.projectname))]
            )
          })
        ],
        2
      ),
      _c("v-client-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: {
          columns: _vm.columns,
          data: _vm.wtsData,
          options: _vm.options,
          theme: _vm.theme,
          id: "dataTable"
        },
        scopedSlots: _vm._u([
          {
            key: "type",
            fn: function(props) {
              return _c("div", {}, [
                props.row.authority
                  ? _c("span", [
                      _vm._v(
                        "\n        Manager (" +
                          _vm._s(props.row.authority) +
                          ")\n      "
                      )
                    ])
                  : _c("span", [_vm._v("\n        Project Manager\n      ")])
              ])
            }
          },
          {
            key: "passlimit",
            fn: function(props) {
              return _c("div", {}, [
                _vm._v("\n      $" + _vm._s(props.row.passlimit) + "\n    ")
              ])
            }
          },
          {
            key: "actions",
            fn: function(props) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.editModal",
                          modifiers: { editModal: true }
                        }
                      ],
                      attrs: { size: "sm", variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.sendId(
                            props.row.authority,
                            props.row.wtsid,
                            props.row.managerid,
                            props.row.passlimit
                          )
                        }
                      }
                    },
                    [
                      _vm._v("Edit Level "),
                      _c("i", { staticClass: "fa fa-edit" })
                    ]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          ref: "editModal",
          attrs: {
            id: "editModal",
            title: "Edit Limit",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: {
            shown: function($event) {
              _vm.error = false
            }
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit($event)
                }
              }
            },
            [
              _c("label", { staticClass: "mt-0", attrs: { for: "" } }, [
                _vm._v("Pass Limit")
              ]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-dollar-sign" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "passlimit",
                      placeholder: "$100000"
                    },
                    model: {
                      value: _vm.passlimit,
                      callback: function($$v) {
                        _vm.passlimit = $$v
                      },
                      expression: "passlimit"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("passlimit"),
                    expression: "errors.has('passlimit')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("passlimit"),
                      expression: "errors.has('passlimit')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The pass limit is required.")]
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error. Please contact admin. ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }